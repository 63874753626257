// 文件 api/request.js
import axios from 'axios'
// import store from '@/store'
import { Loading } from 'element-ui'
// import { browseRecord } from '@/api/index.js'

let reqCount = 0
// let tokenFail = null
let loading
let count = 0

const service = axios.create({
  baseURL: 'https://qsmanageapi.ichongapp.com', // VUE_API 在.env.development等文件中配置的环境变量 不同环境下的请求地址
  timeout: 6000, // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(
  (request) => {
    reqCount++
    loading = Loading.service({
      fullScreen: true, // 全屏loading
      background: 'transparent', // 背景色
    })
    // if(store.user.token){ // 请求头添加 token与userName
    // 	request.headers.token = store.user.token
    // 	request.headers.userName = store.user.userName
    // }

    return request
  },
  (error) => {
    loading.close()
    return Promise.reject(error)
  },
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    reqCount--
    if (count == 0) {
      count++
      // browseRecord().then()
    }

    if (reqCount === 0) loading.close()
    // if(response.data.respCode === 111){ // token失效处理
    // 	if(!tokenFail){
    // 		tokenFail = setTimeOut(() => {
    // 			// store.dispatch('user/logout') // 退出登陆
    // 			// Message.error(response.data.message) // 提示语
    // 			tokenFail = null
    // 			return new Promise.reject(new Error('token已失效'))
    // 		},200)
    // 	}
    // }
    console.log(response)
    return response.data
  },
  (error) => {
    loading.close()
    reqCount--
    return Promise.reject(error)
  },
)

export default service
