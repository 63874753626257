<template>
   <div @mousewheel="goWheel($event)">
  <el-carousel :style="{height:swiperHeight}" indicator-position="none" ref="swiper" @change="change" :autoplay="false" :loop="false" direction="vertical" @mousewheel="goWheel($event)" class="about">
    <el-carousel-item class="aboutTop dotStyleOne">
      <img  v-lazy="top" alt="">
      <div class="dot1"></div>
        <div class="dot"></div>
    </el-carousel-item>
    <el-carousel-item class="aboutCenter">
      <div class="aboutCenter-top">
        <img v-lazy="centerTop" alt="">
      </div>
      <div class="aboutCenter-center">
        <div class="aboutCenter-center-left">
          <!-- 地图 -->
          <el-amap vid="amapContainer" :amap-manager="amapManager" class="amap-demo"  :events="events">
            <el-amap-marker  />
          </el-amap>

        </div>
        <div class="aboutCenter-center-right">
          <p>邮箱：{{ detailList.email }}</p>
          <p>服务时间：{{ detailList.serverTime }}</p>
          <p>地址：{{ detailList.address }}</p>
          <div class="aboutTop-bottom-right-list">
              <p>微信咨询：</p>
              <img :src="detailList.wxUrl" alt="">
            </div>
        </div>
      </div>
      <!-- <div class="aboutCenter-bottom">
        <div class="aboutCenter-bottom-item" v-for="(item,index) in list" :key="index">
          <div class="aboutCenter-bottom-item-img">
            <img :src="item.img" alt="">

          </div>
          <p>{{item.title}}</p>
        </div>

      </div> -->

    </el-carousel-item>
  </el-carousel>
</div>
</template>
<script>
import {contactInfo} from '@/api/index.js'
import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'
const amapManager = new AMapManager()
export default {
  data() {
    return {
      detailList:{},
      swiperHeight:document.body.clientHeight-52+'PX',
      swiperWidth:document.body.clientWidth+'PX',
      one: true,//以此来控制每次轮播图切换的张数
      swiperIndex: 0,
      top:this.$imgUrl+'about/top.png',
      centerTop:this.$imgUrl+'about/centerTop.png',

      list: [
        { img: this.$imgUrl+'home/code.png', title: '关注微信公众号' },
        { img: this.$imgUrl+'home/code.png', title: '微信小程序' },
        { img: this.$imgUrl+'home/code.png', title: 'ios下载' },
        { img: this.$imgUrl+'home/code.png', title: '安卓下载' },
      ],
      amapManager,
      events: {
      },
      markerList: [
        { marker: [118.052156,24.60758], name: '软件园三期C03栋' },
      ],
    }
  },
  mounted() {
    this.contactInfo()
    lazyAMapApiLoaderInstance.load().then(() => {

      this.map = new window.AMap.Map('amapContainer', {
        center: [118.052156,24.60758], //地图显示的中心位置
        zoom: 20, //地图缩放比例
      })
      this.map.addControl(new window.AMap.ToolBar({
        // 简易缩放模式，默认为 false
        liteStyle: true
      }));
      //地图标记点方法
      this.createMark()
    })
    const that = this
    window.onresize = ()=> {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        that.swiperHeight = (window.fullHeight - 52) + 'PX'
      })()

    }
  },
  methods: {
    contactInfo(){
        contactInfo().then(res=>{
          this.detailList=res.data
        })
      },
    change(e) {
      this.swiperIndex = e
    },
    goWheel(event) {
      if (event.deltaY > 0 && this.one == true) {
        this.$refs.swiper.next();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
        if (this.swiperIndex == 1) {
          this.swiperIndex=2
        }
        if (this.swiperIndex == 2) {
          this.$emit("goEnd",true)
        }else{
          this.$emit("goEnd",false)

        }
      }

      if (event.deltaY < 0 && this.one == true) {
        this.$refs.swiper.prev();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
        this.$emit("goEnd",false)
      }
    },
    createMark() {
      // const icon = new window.AMap.Icon({
      //   size: new window.AMap.Size(24, 24),
      //   image: this.iconPoint,
      //   imageSize: new window.AMap.Size(24, 24)
      // })
      // var labelOffset = new window.AMap.Pixel(0, -5);
      this.markerList.forEach((item) => {
        // const labelContent = "<div class='labelContent'>" + item.name + "</div>"
        const marker = new window.AMap.Marker({
          position: item.marker,
          // icon: icon,
          anchor: 'center', //设置锚点
          offset: new window.AMap.Pixel(0, 0), //设置偏移量
          // label: {
          //   direction: 'top',
          //   content: labelContent,
          //   offset: labelOffset,
            
          // }
        })
        // this.map.add(marker)
        marker.setMap(this.map)
      })
    },
    eventsFun() {
      const self = this
      return {
        // 地图加载完成时执行的方法
        complete() {
          self.createMark()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .dotStyleOne {
    .dot1 {
      border-radius: 50%;
      background-color: rgba($color: #92d5de, $alpha: 0.2);
      position: absolute;
      width: 500PX;
      height: 500PX;
      left: -100PX;
      bottom: -200PX;
      animation: dot 8s ease-in-out infinite;
      animation-delay: -2s;
    }
    .dot:last-child {
      border-radius: 50%;
      background-color: rgba($color: #92d5de, $alpha: 0.2);
      position: absolute;
      width: 500PX;
      height: 500PX;
      right: -200PX;
      top: -200PX;
      animation: dot 8s ease-in-out infinite;
      animation-delay: -2s;
    }
  }
img[lazy="loading"] {
		width: 400PX !important;
		height: 50PX !important;
	}
/deep/ .el-carousel__container {
  height: 100% !important;
}
.about {
  // min-width: 1680PX;
  // min-width: 1920PX;
  .aboutTop {
    width: 100%;
    // height:  894PX;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 860PX;
      height: 637PX;
    }
    @media screen and (max-height: 758PX) {
      img {
      height: 540PX !important;
    }
}
  }
  .aboutCenter {
    background-image: url("http://testshop.ichongapp.com/Introduce/bg.png");
    width: 100%;
    // height: 1006PX;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .aboutCenter-top {
      // margin-top: 70PX;
      img {
        width:450PX;
        height: 73PX;
      }
    }
    .aboutCenter-center {
      width: 818PX;
      height: 268PX;
      margin-top: 63PX;
      display: flex;
      .aboutCenter-center-left {
        height: 100%;
        width: 409PX;
      }
      .aboutCenter-center-right {
        width: 409PX;
        height: 100%;
        background-color: #fff;
        padding: 0PX 0PX 0PX 39PX;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
       justify-content: center;
        p {
          font-size: 18PX;
          color: #333333;
          margin-bottom: 20PX;
          letter-spacing:1PX ;
        }
        p:last-child{
          margin-bottom: 0PX;
        }
        .aboutTop-bottom-right-list{
          display: flex;
          align-items: center;
          img{
            width: 100PX;
            height: 100PX;
            border-radius: 6PX;
            // background-color: #EEEEEE;
          }
        }
      }
    }
    .aboutCenter-bottom {
      display: flex;
      margin-top: 51PX;
      // margin-left: 120PX;
      justify-content: center;
      .aboutCenter-bottom-item:last-child{
        margin-right: 0PX;

      }
      .aboutCenter-bottom-item {
        display: flex;
        flex-direction: column;
        margin-right: 85PX;
        display: flex;
        align-items: center;
        justify-content: center;
        .aboutCenter-bottom-item-img {
          background-color: #fff;
          width: 117PX;
          height: 117PX;
          border-radius: 10PX;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 105PX;
            height: 105PX;
          }
        }
        p {
         
          font-size: 13PX;
          color: #333333;
          margin-top: 14PX;
          letter-spacing:1PX ;
        }
      }
    }
  }
}
/deep/ .amap-touch-toolbar .amap-zoomcontrol{
  width: 25PX;

}
/deep/ .amap-zoom-touch-plus>div, .amap-zoom-touch-minus>div{
  font-size: 15PX;
}
/deep/ .amap-zoom-touch-minus>div{
  font-size: 15PX !important;

}
@keyframes dot {
  0%,
  100% {
    transform: translateX(-5%) translateY(0);
  }
  25% {
    transform: translateX(0) translateY(-5%);
  }
  50% {
    transform: translateX(5%) translateY(0);
  }
  75% {
    transform: translateX(0) translateY(5%);
  }
}
</style>
